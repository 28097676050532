import {lazy} from 'react';
import i18next from 'i18next';
import {getExactHiddenRoute, getExactRoute} from 'util/react';

import EN from './i18n/en.json';
import LT from './i18n/lt.json';
// import {Route, Navigate} from 'react-router-dom';

// TODO: Adjust translations column names regarding response data
i18next.addResourceBundle('en', 'alarm', EN);
i18next.addResourceBundle('lt', 'alarm', LT);

export const AlarmCreateRoute = getExactHiddenRoute('alarm', 'menu.edit', '/alarm/new', lazy(() => import('./layout/AlarmCreateLayout')), null, ['admin', 'master_operator', 'operator']);
export const AlarmEditRoute = getExactHiddenRoute('alarm', 'menu.edit', '/alarm/edit/:id', lazy(() => import('./layout/AlarmEditLayout')), null, ['admin', 'master_operator', 'operator']);
export const AlarmListRoute = getExactRoute('alarm', 'menu.list', '/alarm', lazy(() => import('./layout/AlarmListLayout')), null, ['admin', 'master_operator', 'operator']);


const AlarmRoute = (
  <>
    {AlarmCreateRoute}
    {AlarmListRoute}
    {AlarmEditRoute}
    {/* <Route exact path='/alarm/edit' isHidden element={<Navigate replace to={TaskListRoute.props.path} />}/> */}
  </>
);

export default AlarmRoute;
